<template>
  <div>
    <div class="modal" id="encode-special-order-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form action="" @submit.prevent="submitSpecialOrder">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Encode Special Order
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-sm table-bordered">
                <thead class="text-center">
                  <th>#</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th width="15%">Middle Name</th>
                  <th>Sex</th>
                  <th width="15%">Sem Started</th>
                  <th width="17%">AY Started</th>
                </thead>
                <tbody>
                  <tr v-for="(student, index) in studentList" :key="index">
                    <th>{{ index + 1 }}</th>
                    <td>
                      <input
                        type="text"
                        class="form-control text-uppercase form-control-sm"
                        v-model="student.lastName"
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control text-uppercase form-control-sm"
                        v-model="student.firstName"
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control text-uppercase form-control-sm"
                        v-model="student.middleName"
                      />
                    </td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control form-control-sm"
                        v-model="student.gender"
                        required
                      >
                        <option value="" hidden selected disabled>Choose</option
                        ><option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control form-control-sm"
                        v-model="student.semEnrolled"
                        required
                      >
                        <option value="" hidden selected disabled
                          >Choose</option
                        >
                        <option value="1ST SEMESTER">1ST SEMESTER</option>
                        <option value="2ND SEMESTER">2ND SEMESTER</option>
                        <option value="3RD SEMESTER">3RD SEMESTER</option>
                        <option value="SUMMER">SUMMER</option>
                        <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                        <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                        <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name=""
                        id=""
                        class="form-control  form-control-sm"
                        v-model="student.acadYear"
                        required
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >

                        <option
                          v-for="ay in acadYears"
                          :key="ay.id"
                          :value="ay.academic_year"
                          >{{ ay.academic_year }}</option
                        >
                        <option value="2014-2015">2014-2015</option>
                        <option value="2013-2014">2013-2014</option>
                        <option value="2012-2013">2012-2013</option>
                        <option value="2011-2012">2011-2012</option>
                        <option value="2010-2011">2010-2011</option>
                        <option value="2009-2010">2009-2010</option>
                        <option value="2008-2009">2008-2009</option>
                        <option value="2007-2008">2007-2008</option>
                        <option value="2006-2007">2006-2007</option>
                        <option value="2005-2006">2005-2006</option>
                        <option value="2004-2005">2004-2005</option>
                        <option value="2003-2004">2003-2004</option>
                        <option value="2002-2003">2002-2003</option>
                        <option value="2001-2002">2001-2002</option>
                        <option value="2000-2001">2000-2001</option>
                        <option value="1999-2000">1999-2000</option>
                        <option value="1998-1999">1998-1999</option>
                        <option value="1997-1998">1997-1998</option>
                        <option value="1996-1997">1996-1997</option>
                        <option value="1995-1996">1995-1996</option>
                        <option value="1994-1995">1994-1995</option>
                        <option value="1993-1994">1993-1994</option>
                        <option value="1992-1993">1992-1993</option>
                        <option value="1991-1992">1991-1992</option>
                        <option value="1990-1991">1990-1991</option>
                        <option value="1989-1990">1989-1990</option>
                        <option value="1988-1989">1988-1989</option>
                        <option value="1987-1988">1987-1988</option>
                        <option value="1986-1987">1986-1987</option>
                        <option value="1985-1986">1985-1986</option>
                        <option value="1984-1985">1984-1985</option>
                        <option value="1983-1984">1983-1984</option>
                        <option value="1982-1983">1982-1983</option>
                        <option value="1981-1982">1981-1982</option>
                        <option value="1980-1981">1980-1981</option>
                        <option value="1979-1980">1979-1980</option>
                        <option value="1978-1979">1978-1979</option>
                        <option value="1977-1978">1977-1978</option>
                        <option value="1976-1977">1976-1977</option>
                        <option value="1975-1976">1975-1976</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="btn-group">
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click="addStudent"
                >
                  <i class="bi bi-plus  icon-sm "></i>
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click="deleteStudent"
                >
                  <i class="bi bi-dash  icon-sm"></i>
                </button>
              </div>

              <hr class="dotted" />

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Date Issued</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.dateIssued"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Date of Graduation</label>
                    <input
                      type="text"
                      class="form-control text-uppercase"
                      v-model="form.gradDate"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <label for="">Institution</label>
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="form.schoolCode"
                    @change="getHeiPrograms(form.schoolCode)"
                    required
                  >
                    <option value="" selected disabled hidden>Choose</option>
                    <option
                      v-for="hei in heis"
                      :key="hei.code"
                      :value="hei.code"
                      >{{ `${hei.name} (${hei.abbrev.toUpperCase()})` }}</option
                    >
                  </select>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Program/Degree</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      required
                      v-model="selected_program"
                      @change="setLevelAndDisciplineCode"
                      :disabled="!form.schoolCode"
                    >
                      <option value="" selected disabled hidden>Choose</option>
                      <option
                        v-for="hei_program in hei_programs"
                        :key="hei_program.hei_program_id"
                        :value="hei_program"
                        >{{ hei_program.program_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col">
                  <label for="">Special Order No</label>
                  <div v-if="!isExempted" class="input-group">
                    <select
                      name=""
                      id=""
                      class="form-control "
                      v-model="form.soLevel"
                      required
                    >
                      <option value="(40)">(40)</option>
                      <option value="(50)">(50)</option>
                      <option value="(80)">(80)</option>
                      <option value="(90)">(90)</option>
                      <option value="(A)">(A)</option>
                      <option value="(B)">(B)</option>
                      <option value="(C)">(C)</option>
                      <option value="(D)">(D)</option>
                      <option value="">Blank</option>
                    </select>

                    <select
                      name=""
                      id=""
                      class="form-control "
                      v-model="form.soRegion"
                      required
                    >
                      <option value="(R-XIII)">(R-XIII)</option>
                      <option value="(R-XII)">(R-XII)</option>
                      <option value="(R-XI)">(R-XI)</option>
                      <option value="(R-X)">(R-X)</option>
                      <option value="">Blank</option>
                    </select>
                    <input
                      type="text"
                      class="form-control "
                      v-model="form.soNumber"
                      placeholder="Discipline Code"
                      maxlength="6"
                      required
                    />

                    <input
                      type="text"
                      class="form-control "
                      v-model="form.soSequence"
                      placeholder="Sequence No"
                      maxlength="4"
                    />
                    <input
                      type="text"
                      class="form-control "
                      v-model="form.soSeries"
                      placeholder="Series"
                      maxlength="4"
                    />
                  </div>
                  <div class="" v-else>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter S.O. exemption here "
                      v-model="form.soNumber"
                    />
                  </div>
                  <div class="form-text">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="isExempted"
                        @change="setExemption"
                      />
                      <label class="form-check-label">
                        S.O. Exempted
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Semester & Academic Year Ended</label>
                    <div class="input-group">
                      <select
                        name=""
                        id=""
                        class="form-control  "
                        v-model="form.semStarted"
                        required
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option value="1ST SEMESTER">1ST SEMESTER</option>
                        <option value="2ND SEMESTER">2ND SEMESTER</option>
                        <option value="3RD SEMESTER">3RD SEMESTER</option>
                        <option value="SUMMER">SUMMER</option>
                        <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                        <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                        <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                      </select>
                      <select
                        name=""
                        id=""
                        class="form-control  "
                        v-model="form.semYear"
                        required
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option
                          v-for="ay in acadYears"
                          :key="ay.id"
                          :value="ay.academic_year"
                          >{{ ay.academic_year }}</option
                        >

                        <option value="2014-2015">2014-2015</option>
                        <option value="2013-2014">2013-2014</option>
                        <option value="2012-2013">2012-2013</option>
                        <option value="2011-2012">2011-2012</option>
                        <option value="2010-2011">2010-2011</option>
                        <option value="2009-2010">2009-2010</option>
                        <option value="2008-2009">2008-2009</option>
                        <option value="2007-2008">2007-2008</option>
                        <option value="2006-2007">2006-2007</option>
                        <option value="2005-2006">2005-2006</option>
                        <option value="2004-2005">2004-2005</option>
                        <option value="2003-2004">2003-2004</option>
                        <option value="2002-2003">2002-2003</option>
                        <option value="2001-2002">2001-2002</option>
                        <option value="2000-2001">2000-2001</option>
                        <option value="1999-2000">1999-2000</option>
                        <option value="1998-1999">1998-1999</option>
                        <option value="1997-1998">1997-1998</option>
                        <option value="1996-1997">1996-1997</option>
                        <option value="1995-1996">1995-1996</option>
                        <option value="1994-1995">1994-1995</option>
                        <option value="1993-1994">1993-1994</option>
                        <option value="1992-1993">1992-1993</option>
                        <option value="1991-1992">1991-1992</option>
                        <option value="1990-1991">1990-1991</option>
                        <option value="1989-1990">1989-1990</option>
                        <option value="1988-1989">1988-1989</option>
                        <option value="1987-1988">1987-1988</option>
                        <option value="1986-1987">1986-1987</option>
                        <option value="1985-1986">1985-1986</option>
                        <option value="1984-1985">1984-1985</option>
                        <option value="1983-1984">1983-1984</option>
                        <option value="1982-1983">1982-1983</option>
                        <option value="1981-1982">1981-1982</option>
                        <option value="1980-1981">1980-1981</option>
                        <option value="1979-1980">1979-1980</option>
                        <option value="1978-1979">1978-1979</option>
                        <option value="1977-1978">1977-1978</option>
                        <option value="1976-1977">1976-1977</option>
                        <option value="1975-1976">1975-1976</option>
                        <option value="1974-1975">1974-1975</option>
                        <option value="1973-1974">1973-1974</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Signed by</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="form.soSignedBy"
                      required
                    >
                      <option value="ALMA SY-PATRON, JD, MSW, RSW" selected
                        >ALMA SY-PATRON, JD, MSW, RSW</option
                      >
                      <option value="DR. GEORGE M. COLORADO, CESO III"
                        >DR. GEORGE M. COLORADO, CESO III</option
                      >

                      <option value="LEONIDA S. CALAGUI, PH.D., CESO III"
                        >LEONIDA S. CALAGUI, PH.D., CESO III</option
                      >
                      <option value="NA">NA</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">File</label>
                <input
                  id="so-file-input"
                  type="file"
                  class="form-control-file"
                  accept="application/pdf"
                  @change="getFile"
                  required
                />
                <small class="form-text text-muted"
                  >File must be on PDF format only</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                CLOSE
              </button>
              <button id="submit-so-btn" type="submit" class="btn btn-primary">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EncodeSpecialOrder',
  data() {
    return {
      heis: [],
      selected_program: '',
      hei_programs: [],
      acadYears: [],
      isExempted: false,
      studentList: [
        {
          lastName: '',
          firstName: '',
          middleName: '',
          gender: '',
          acadYear: '',
          semEnrolled: '',
        },
      ],
      form: new Form({
        dateIssued: '',
        schoolCode: '',
        progName: '',
        semStarted: '',
        semYear: '',
        soLevel: '(50)',
        soRegion: '(R-XIII)',
        gradDate: '',
        soSeries: new Date().getFullYear(),
        soNumber: '',
        soSequence: '',
        soSignedBy: 'ALMA SY-PATRON, JD, MSW, RSW',
        file: '',
      }),
    };
  },
  methods: {
    clearForm() {
      this.form.reset();
      this.selected_program = '';
      document.getElementById('so-file-input').value = null;
      while (this.studentList.length > 0) {
        this.studentList.pop();
      }
      this.addStudent();
    },
    setExemption() {
      this.form.soLevel = this.form.soRegion = this.form.soSeries = this.form.soNumber =
        '';
    },
    submitSpecialOrder() {
      let btn = document.getElementById('submit-so-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm"></div> SUBMITTING...`;
      btn.disabled = true;

      let fd = this.setFormData();

      axios
        .post('api/old-database/special-orders', fd)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          btn.disabled = false;
          btn.innerHTML = 'SUBMIT';
          $('#encode-special-order-modal').modal('hide');
          this.$emit('getSpecialOrders');
          this.clearForm();
        })
        .catch((err) => {
          toastr.error('Something went wrong!', 'Error');
          btn.disabled = false;
          btn.innerHTML = 'SUBMIT';
        });
    },
    setLevelAndDisciplineCode() {
      this.form.progName = this.selected_program.program_name;
      this.form.soNumber = this.selected_program.discipline_code;
    },
    setFormData() {
      let fd = new FormData();

      fd.append('dateIssued', this.form.dateIssued);
      fd.append('studentList', JSON.stringify(this.studentList));
      fd.append('schoolCode', this.form.schoolCode);
      fd.append('progName', this.form.progName);
      fd.append('semStarted', this.form.semStarted);
      fd.append('semYear', this.form.semYear);
      fd.append('soLevel', this.form.soLevel);
      fd.append('soRegion', this.form.soRegion);
      fd.append('gradDate', this.form.gradDate);
      fd.append('grNo', this.form.grNo);
      fd.append('grSeries', this.form.grSeries);
      fd.append('soSeries', this.form.soSeries);
      fd.append('soNumber', this.form.soNumber);
      fd.append('soSequence', this.form.soSequence);
      fd.append('soSignedBy', this.form.soSignedBy);
      fd.append('file', this.form.file);

      return fd;
    },
    openModal() {
      $('#encode-special-order-modal').modal('show');
      this.getHeis();
      this.getAcademicYears();
    },
    addStudent() {
      this.studentList.push({
        lastName: '',
        firstName: '',
        middleName: '',
        gender: '',
        semStarted: '',
        acadYear: '',
      });
    },
    deleteStudent() {
      this.studentList.pop();
    },
    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHeiPrograms(hei_code) {
      axios
        .get('api/hei-programs/' + hei_code)
        .then((response) => {
          this.hei_programs = response.data;
        })
        .catch((error) => {
          toastr.error('Something went wrong', 'Request failed');
        });
    },
    getAcademicYears() {
      axios
        .get('api/academic-years')
        .then((response) => {
          this.acadYears = response.data;
        })
        .catch((error) => {
          toastr.error(error.response.data.message, 'Error');
        });
    },
    getFile(event) {
      this.form.file = event.target.files[0];
    },
  },
  mounted() {},
};
</script>
